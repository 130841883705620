export function smoothScrool(){
    var navBarH=$($('.navbar').is(':visible')?'.navbar':'.navbar-mobile').outerHeight();
    /*console.log(navBarH);*/
    $("a[href^='#']:not(.panel-title)").click(function(){
        $('html, body').animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top-navBarH
        }, 500);
        return false;
    });
}
export function goTop() {
    if ($('#presidents').length){
    $('#presidents').append('<a id="toTop" class="btn-top" href="#page-wrapper"><i class="fa fa-arrow-up" aria-hidden="true"></i></a>');
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $('#toTop').fadeIn();
        } else {
            $('#toTop').fadeOut();
        }
    });
    }
}
